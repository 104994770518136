import React from "react";
import { useTranslation } from "react-i18next";

export default function Containt() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="relative isolate overflow-hidden bg-gray-900">
        <div className="html-content mx-auto max-w-7xl p-4 xl:px-0">
          <h1 className="font-bold text-3xl">{t("DelvingintoOnlineCasinoEntertainmentYourUltimateGuidetoCasinexCasino")} 
          </h1>
          <h2 className="font-bold mt-4">
             {t("WelcometoCasinexYourEliteOnlineCasinoHaven")} 
          </h2>
          <p>
           {t("CasinexhasrisenasaremarkabledestinationintheonlinecasinoworlddeliveringavastassortmentofgamescateringtodiverseplayerpreferencesFoundedtocreateadistinctiveandexcitinggamingenvironmentCasinexseamlesslyintegratestimehonouredcasinotraditionswiththelatestindigitalinnovation")}
          </p>
          <h2 className="font-bold mt-4">
            {t("ARichSelectionofFirstRateCasinoGames")}
          </h2>
          <p className="font-bold mt-4">
            {t("Casinexisproudtopresentawiderangingselectionofcasinogamesincluding")}
          </p>

          <p className="font-bold mt-4">
           {t("SlotMachinesVariedThemesforEveryTaste")}
          </p>
          <ul>
            <li>
              <span className="font-bold ">{t("ClassicSlots")}:</span>{t("Evergreenfavouritesforpurists")}
            </li>
            <li>
              <span className="font-bold ">{t("VideoSlots")}:</span> {t("Advancedgraphicswithengagingnarratives")} 
            </li>

            <li>
              <span className="font-bold ">{t("ProgressiveJackpots")}:</span> 
              {t("Chancesformonumentalvictories")} 
            </li>
          </ul>

          <p className="font-bold mt-4">
            <span className="font-bold "> {t("TableGames")}:</span> 
            {t("TheQuintessenceofCasinoSophistication")}
          </p>
          <ul>
            <li>
              <span className="font-bold ">{t("RouletteOptions")}:</span>
              {t("AmericanEuropeanandFrenchversions")}
            </li>
            <li>
              <span className="font-bold ">{t("Blackjack")}:</span>
              {t("Diverserulesandbettingoptions")} 
            </li>
            <li>
              <span className="font-bold ">{t("PokerVarieties")}:</span>{t("FromTexasHoldemtoCaribbeanStud")}  
            </li>
          </ul>

          <p className="font-bold mt-4">
            <span className="font-bold ">{t("LiveCasino")}:</span> 
            {t("ThrillsinRealTime")}
          </p>
          <ul>
            <li>
              <span className="font-bold ">{t("LiveDealers")}:</span>{t("Transportingthecasinovibetoyourspace")} 
            </li>
            <li>
              <span className="font-bold ">{t("InteractiveElements")}:</span> {t("Engagewithdealersandfellow")}  
            </li>
            <li>
              <span className="font-bold ">{t("GenuineCasinoFeel")}:</span> {t("Theauthenticcasinoexperienceatyourfingertips")}
            </li>
          </ul>

          <h2 className="font-bold mt-4">{t("UserInterfaceandExperience")}
          </h2>
          <p>{t("AtCasinexweprioritiseaseamlessuserexperienceOurwebsitesdesignisuserfriendlyensuringeasynavigationforbothnewbiesandseasonedgamersTheplatformisoptimisedforavarietyofdevicesguaranteeingafluidgamingexperienceacrossdesktopstabletsandmobilephones")}
          </p>

          <h2 className="font-bold mt-4">{t("SafetyandIntegrity")}
          </h2>
          <p>
            {t("CasinexisdeeplycommittedtosecurityWeusesophisticatedencryptiontoprotectuserdataandfinancialtransactionsIntegrityiskeywithregularindependentauditstoensuregamefairnessandrandomoutcomes")}
          </p>

          <h2 className="font-bold mt-4"> {t("IncentivesandPromotions")} 
          </h2>
          <p>
             {t("Casinexoffersarangeofenticingbonusesandpromotions")} 
          </p>
          <ul>
            <li>
              <span className="font-bold ">{t("WelcomeOffers")}  :</span> 
              {t("Appealingbonusesfornewcomers")}  
            </li>
            <li>
              <span className="font-bold ">{t("OngoingPromotions")}:</span> 

              {t("Frequentrewardsincludingweeklyandmonthlybonuses")}
            </li>
            <li>
              <span className="font-bold ">{t("VIPClub")}:</span> {t("Exclusiveperksfordedicatedmembers")}  
            </li>
          </ul>

          <h2 className="font-bold mt-4"> {t("CustomerServiceHereWhenYouNeedUs")}
          </h2>
          <p> {t("Ourcustomersupportiscomprehensiveandalwaysavailable")}
          </p>
          <ul>
            <li>
              <span className="font-bold ">{t("LiveChat")}:</span>  {t("Instanthelpatyourfingertips")}
            </li>
            <li>
              <span className="font-bold ">{t("EmailAssistance")}:</span> {t("Formoredetailedenquiries")} 
            </li>
            <li>
              <span className="font-bold ">{t("ExtensiveFAQSection")}:</span> {t("Quickanswerstocommonquestions")}
            </li>
          </ul>

          <h2 className="font-bold mt-4">{t("ResponsibleGamblingOurCommitmenttoPlayerSafety")}
          </h2>
          <p className="">  {t("CasinexisdevotedtoresponsiblegamblingpracticesWeprovidetoolsandresourcestoassistplayersinmanagingtheirgamingactivitieslikedepositlimitsselfexclusionoptionsandaccesstoprofessionaladvice")} 
          </p>
          <h2 className="font-bold mt-4">
             {t("ConclusionThePremierChoiceforOnlineCasinoAficionados")}
          </h2>
          <p>{t("CasinexdistinguishesitselfasaleadingonlinecasinodestinationOurvastlibraryofgamesuserfriendlyinterfacestrongsecurityprotocolsattractivebonusesanddedicationtoresponsiblegamingmakeCasinexanincomparablechoiceforonlinecasinoenthusiastsJoinusatCasinexforagamingadventurewhereexcellenceandunmatchedserviceconverge")}

          </p>
        </div>
        <div
          className="absolute top-0 -z-5 -translate-x-10 blur-3xl xl:top-20"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#a3e635] to-[#a3e635] opacity-15"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
