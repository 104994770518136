/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import logo from "../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/Casinex-logo.png";

import { Link } from "react-router-dom";
import Services from "../../services";
import DomPurify from "dompurify";
import "./Footer.css";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const [footerdata, setFooterData] = useState();
  const { t, i18n } = useTranslation();
  const language = localStorage.getItem("i18nextLng");


  const GET_FOOTER = async () => {
    try {
      const query = {
        whitelabelId: 232,
        country: localStorage.getItem("Location"),
        lang: language,
        code: "footerclient",
      };
      const queryParams = new URLSearchParams(query).toString();
      const response = await Services.Footer.GET_FOOTER(queryParams);
      setFooterData(response?.data[0]?.Html);
    } catch (error) {
      console.error("Error fetching footer:", error);
    }
  };

  const getDescription = () => {
    if (footerdata) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(footerdata, 'text/html');

      const withoutDisclaimer = Array.from(doc.body.childNodes)
        .filter(node => !node.classList || !node.classList.contains('disclaimer'))
        .map(node => new XMLSerializer().serializeToString(node))
        .join('');

      return { __html: withoutDisclaimer };
    }
  };

  useEffect(() => {
    GET_FOOTER();
  }, []);
  return (
    <div>
      <div className="relative isolate overflow-hidden bg-gray-900 p-4 sm:py-16 border-t border-gray-600">
        <div className="mx-auto max-w-7xl pb-6 lg:pb-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-6">
            <div className="max-w-xl lg:max-w-lg">
              <Link to='/'><img className="h-8 w-auto" src={logo} /></Link>
              
            </div>
            <div className="grid grid-cols-2 colspan-5 gap-x-2 gap-y-2 font-semibold leading-7 text-white text-sm sm:grid-cols-2 md:flex lg:gap-x-10">
              <Link to="/" target="_self" rel="noopener noreferrer">
                {t("Home")}
              </Link>
              <Link
                to="https://www.casinex.com/about-us-info"
                target="_self"
                rel="noopener noreferrer"
              >{t("About")}
                
              </Link>
              <Link
                to="https://www.casinex.com/deposits-info"
                target="_self"
                rel="noopener noreferrer"
              >
                {t("Deposits")}
                
              </Link>
              <Link
                to="https://www.casinex.com/withdrawals-info"
                target="_self"
                rel="noopener noreferrer"
              >
                {t("Withdrawals")}

              </Link>
              <Link
                to="https://www.casinex.com/rewards-programme-info"
                target="_self"
                rel="noopener noreferrer"
              >
                {t("Rewards")}
                
              </Link>
              <Link
                to="https://www.casinex.com/game-payouts-info"
                target="_self"
                rel="noopener noreferrer"
              >
                {t("Payouts")}
                
              </Link>
              <Link to="/disclaimer" target="_blank" rel="noopener noreferrer">
              {t("Disclaimer")}
                
              </Link>
              <Link
                to="https://www.casinex.com/terms-conditions-info"
                target="_self"
                rel="noopener noreferrer"
              >
                {t("Terms")}
                
              </Link>
              <Link
                to="https://www.casinex.com/privacy-policy-info"
                target="_self"
                rel="noopener noreferrer"
              >
                {t("Privacy")}
                
              </Link>
              <Link
                to="https://www.casinex.com/casino-rules"
                target="_self"
                rel="noopener noreferrer"
              >
                {t("Rules")}
                
              </Link>
              <Link
                to="https://rubyaffiliates.com/"
                target="_self"
                rel="noopener noreferrer"
              >
                {t("Affiliates")}
                
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10 mt-16 lg:mt-16 ">
            <div className="colspan-2">
              <p class="text-white font-semibold">{t("Casinex©2024")}</p>
              <p class="mt-4 leading-8 text-gray-300 text-sm">
              {t("CasinexisoperatedbyProgressPlayLimitedofSohoOfficePunchbowlCentreEliaZammitStreetStJuliansSTJ3154MaltaProgressPlayisalimitedliabilitycompanyregisteredinMaltathatislicensedandregulatedbyMaltaGamingAuthorityandoperatesunderaLicenseNumberofissuedonthAprilProgressPlayLimitedislicensedandregulatedinGreatBritainbytheGamblingCommissionunderaccountnumberGamblingcanbeaddictivePlayresponsibly")}
                
              </p>
            </div>
            <dl className="grid grid-cols-1 gap-x-8 gap-y-10  lg:pt-2">

              <div class="flex flex-col flex-column items-start">
                <div class="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                  <svg

                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-6 w-6 text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002"
                    ></path>
                  </svg>
                </div>
                <dt class="mt-4 font-semibold text-white">
                {t("ResponsibleGambling")}
                </dt>
                <dd class="mt-4 leading-8 text-gray-300 text-sm">
                {t("AtCasinexwearecommittedtoresponsiblegamblingthaswhyweofferavarietyoftoolstohelpyoumanageyourgaming")}
                  
                </dd>
              </div>
            </dl>
          </div>


          {footerdata && <div dangerouslySetInnerHTML={getDescription()} />}
        </div>
        <p className="grid grid-cols-1 gap-1 place-content-center place-items-center ">
        {t("CasinexCasinoAllRightsReserved")}
          
        </p>
        <div
          className="absolute left-1/2 top-0 -z-10 -translate-x-10 blur-3xl xl:top-20"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#a3e635] to-[#a3e635] opacity-15"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
