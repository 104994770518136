import React, { useEffect, useState } from "react";
import "../../App.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useGetTitle from "../../hooks/useGetTitle";
import Footer from "../Footer/Footer";
import reward from "../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/image1111.webp";
import plus from "../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/18.png";
import CardSlider from "../PaymentCardSlider/CardSlider";
import ProductInfo from "../ProductInfo/ProductInfo";
import Promotion from "../promotions/Promotion";
import Containt from "../content/Containt";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Loader from "../loader/Loader";
import { useTranslation } from "react-i18next";

export default function Casino() {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const counter = useSelector(state => state.counter.value);

  const isMobile = window.innerWidth <= 1440;

  const containerwidth = {
    width: isMobile ? "full" : "auto",
  };

  const options = {
    items: 1,
    loop: true,
    autoplay: true,
  };

  const { disclamerdata, TITLE_DISCLAMER } = useGetTitle();

  const LOCATION = async () => {
    setLoading(true);
    try {
     const res= await TITLE_DISCLAMER(localStorage.getItem("Location"), "casino");
 
      // setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if(counter>0){
      LOCATION();
    }
  }, [counter]);
  return (
    <>
      { !disclamerdata?.title ?(
        <div className="loader flex  justify-center content-center self-center items-center	m-20 justify-self-center	">
          <Loader />
        </div>
      ) : (
        <div>
          <div>
            <main className="relative z-10  transition-transform duration-300 ease-in-out">
              <div className="mt-16 lg:mt-0 relative isolate overflow-hidden bg-gray-900 py-0 lg:py-16">
                <div />

                <div
                  className="hidden lg:absolute lg:-top-10 lg:right-1/2 lg:-z-10 lg:mr-10 lg:block lg:transform-gpu lg:blur-3xl"
                  aria-hidden="true"
                >
                  {/* bg-gradient-to-tr from-[#a3e635] to-[#a3e635] opacity-15 */}
                  <div
                    className="aspect-[1097/845] w-[68.5625rem] "
                    style={{
                      clipPath:
                        "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                  />
                </div>
                <div
                  className="carousel-buner__item-bg pl-2 container"
                  style={containerwidth}
                >
                  <div className="carousel-buner__item-typography disclamershow max-w-[1240px] mx-auto  p-5">
                    <h2 className="carousel-buner__title">
                      {disclamerdata?.title}
                    </h2>
                    <p className="mt-3 text-lg leading-8 text-gray-300">
                      {disclamerdata?.subTitle}
                    </p>
                    <div className="mt-2 carousel-buner__buttons">
                      <Link to="https://www.casinex.com/?dynamic=organic&btag=organic&a=default/#m/registration">
                        <button className="btn btn-light ">{t("DepositNow")}</button>
                      </Link>
                      <Link to="https://www.casinex.com/promotions?dynamic=organic&btag=organic&code=Welcome">
                        <button className="text-sm font-medium text-slate-50 hover:text-blue-700 ">
                          {t("Termsapply")}
                        </button>
                      </Link>
                      <p className="carousel-buner__sub-title  ">
                        {disclamerdata?.disclaimer}
                      </p>
                    </div>
                  </div>
                  <div className="flex  lg:block hidden"
                  style={{width:"70%" ,height:"70%"}}
                  >
                    <img
                      alt="Bet ₹ 1,000 & Get a ₹ 1,000 Free Bet"
                      srcSet={reward}
                      src={reward}
                      width={384}
                      height={307}
                      decoding="async"
                      data-nimg={1}
                      className="character_img__ela10"
                      loading="lazy"
                      style={{
                        color: "transparent",
                        display: "block",
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "right center",
                      }}
                    />
                  </div>

                  {disclamerdata&&disclamerdata?(  <Link
                    to="https://casinex.casino-pp.net/#info/responsible"
                    className="pr-4 pt-1  "
                  > <img
                      // src="https://data.progressplay.net/content/general/icons/18.png"
                      src={plus}
                       alt="18+"
                      className="rounded-full w-auto iconimg  "
                    />
                  </Link>):(<></>)}
                
                </div>
              </div>
            </main>
          </div>
          <CardSlider />
          <ProductInfo />
          <Promotion />
          <Containt />
          <Footer />
        </div>
      )}
    </>
  );
}
